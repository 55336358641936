import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToHome } from 'helpers/paths';

import PageTitle from 'components/PageTitle';

import Band from './Band';
import Bands from './Bands';

const CompensationRoutes = () => {
  const organization = useCurrentOrganization();

  const canShowCompensationBands = can({
    perform: 'show_compensation_menu',
    on: organization,
  });

  return (
    <>
      <PageTitle title={__('Compensation')} />

      <Routes>
        {canShowCompensationBands && (
          <>
            <Route path="bands" element={<Bands />} />
            <Route path="bands/:id" element={<Band />} />
          </>
        )}

        <Route path="*" element={<Navigate to={pathToHome()} />} />
      </Routes>
    </>
  );
};

export default CompensationRoutes;
