import React from 'react';

import type { Priority } from 'models';
import type { ReactNode } from 'react';

import invariant from 'helpers/invariant';
import { priorityLabel } from 'helpers/models/trainingRequest';

import { Icon, Select } from 'components';

type Option = {
  value: Priority;
  label: string;
  icon: ReactNode;
};

type Props = {
  value: Priority | undefined | null;
  onChange: undefined | ((priority: Priority) => void);
  disabled?: boolean;
};

const PriorityPicker = ({ value, onChange, disabled }: Props) => {
  const options: Array<Option> = [
    {
      value: 'high',
      label: priorityLabel('high'),
      icon: <Icon color="danger" name="arrow_upward" />,
    },
    {
      value: 'medium',
      label: priorityLabel('medium'),
      icon: <Icon color="warning" name="arrow_upward" />,
    },
    {
      value: 'low',
      label: priorityLabel('low'),
      icon: <Icon color="success" name="arrow_downward" />,
    },
  ];

  return (
    <Select
      value={options.find(option => option.value === value) || null}
      isDisabled={disabled}
      options={options}
      onChange={option => {
        invariant(
          option && !Array.isArray(option),
          'Option must exist and cannot be an array'
        );
        invariant(onChange, 'onChange is required');

        onChange(option.value);
      }}
      isMulti={false}
      isClearable={false}
      menuPlacement="top"
      inModal
    />
  );
};

export default PriorityPicker;
