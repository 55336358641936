import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { compose } from 'redux';

import { CompensationBandContent } from 'models/Compensation';

import { pathToCompensationBands } from 'helpers/paths';

import { highLevelErrorHandler } from 'lib/api';
import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer } from 'components';
import { withRouter } from 'components/HOCs/withRouter';

import BandContent from './BandContent';
import BandHeader from './BandHeader';

type AfterRouterProps = {
  match: { id: string };
  navigate: NavigateFunction;
};

type AfterDataLoaderProps = AfterRouterProps &
  DataLoaderProvidedProps & {
    compensationBand: CompensationBandContent;
  };

const Band = ({
  compensationBand,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <>
            <BandHeader compensationBand={compensationBand} />

            <ContentContainer>
              <BandContent compensationBand={compensationBand} />
            </ContentContainer>
          </>
        );
      }}
    />
  );
};

export default compose<React.ComponentType>(
  withRouter,
  newDataLoader({
    fetch: ({ match, navigate }: AfterRouterProps) =>
      get(`compensation/bands/${match.id}`, undefined, {
        customErrorHandler: (error, dispatch) => {
          if (
            error?.response?.statusCode === 401 ||
            error?.response?.statusCode === 404
          ) {
            navigate(pathToCompensationBands());
          }

          highLevelErrorHandler(error, dispatch);
        },
      }),
    hydrate: {
      compensationBand: {
        levels: {
          remunerations: { remunerationType: {} },
        },
      },
    },
  })
)(Band);
